// GestionarConteosModal.jsx
import React, { useEffect, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Tabs,
  Tab,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import api from './api';
import { io } from 'socket.io-client';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: 800 },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflowY: 'auto',
  };
  
const GestionarConteosModal = ({ open, handleClose }) => {
  const [tabIndex, setTabIndex] = useState(0);

  // States for tipo_conteo
  const [tiposConteo, setTiposConteo] = useState([]);
  const [nuevoTipo, setNuevoTipo] = useState('');
  const [editingTipo, setEditingTipo] = useState(null);
  const [tipoTemp, setTipoTemp] = useState('');

  // States for conteos
  const [conteos, setConteos] = useState([]);
  const [nuevoConteo, setNuevoConteo] = useState({
    fecha: new Date().toISOString().split('T')[0], // Fecha actual en formato YYYY-MM-DD
    nombreConteo: '',
    idSupervisor: '',
  });
  const [editingConteo, setEditingConteo] = useState(null);
  const [conteoTemp, setConteoTemp] = useState({
    fecha: '',
    nombreConteo: '',
    idSupervisor: '',
    finalizado: false,
  });

  // States for users (supervisors)
  const [usuarios, setUsuarios] = useState([]);

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  // Conectar a Socket.IO para actualizaciones en tiempo real
  useEffect(() => {
    const socket = io('https://dicsapps.space:10000'); // Asegúrate de usar la URL correcta

    // Escuchar eventos de actualización de tipos de conteo
    socket.on('tiposConteoActualizados', (updatedTipo) => {
      setTiposConteo((prevTipos) => {
        const exists = prevTipos.some((tipo) => tipo.id === updatedTipo.id);
        if (exists) {
          return prevTipos.map((tipo) => (tipo.id === updatedTipo.id ? updatedTipo : tipo));
        } else {
          return [...prevTipos, updatedTipo];
        }
      });
    });

    socket.on('tiposConteoEliminados', (tipoId) => {
      setTiposConteo((prevTipos) => prevTipos.filter((tipo) => tipo.id !== tipoId));
    });

    // Escuchar eventos de actualización de conteos
    socket.on('conteosActualizados', (updatedConteo) => {
      setConteos((prevConteos) => {
        const exists = prevConteos.some((conteo) => conteo.id === updatedConteo.id);
        if (exists) {
          return prevConteos.map((conteo) => (conteo.id === updatedConteo.id ? updatedConteo : conteo));
        } else {
          return [...prevConteos, updatedConteo];
        }
      });
    });

    socket.on('conteosEliminados', (conteoId) => {
      setConteos((prevConteos) => prevConteos.filter((conteo) => conteo.id !== conteoId));
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // Fetch initial data when modal opens
  useEffect(() => {
    if (open) {
      fetchTiposConteo();
      fetchConteos();
      fetchUsuarios();
    }
  }, [open]);

  // Fetch tipos_conteo
  const fetchTiposConteo = async () => {
    try {
      const response = await api.get('/tipos_conteo');
      if (response.data.success) {
        setTiposConteo(response.data.tipos);
      }
    } catch (error) {
      console.error('Error fetching tipos_conteo:', error);
      setNotification({
        open: true,
        message: 'Error al obtener los tipos de conteo.',
        severity: 'error',
      });
    }
  };

  // Fetch conteos
  const fetchConteos = async () => {
    try {
      const response = await api.get('/conteos_lista'); // Asegúrate de que este endpoint devuelve los datos necesarios
      if (response.data.success) {
        setConteos(response.data.conteos);
      }
    } catch (error) {
      console.error('Error fetching conteos:', error);
      setNotification({
        open: true,
        message: 'Error al obtener los conteos.',
        severity: 'error',
      });
    }
  };

  // Fetch usuarios (supervisores)
  const fetchUsuarios = async () => {
    try {
      const response = await api.get('/usuarios'); // Asegúrate de que este endpoint está correctamente definido
      if (response.data.success) {
        setUsuarios(response.data.usuarios);
      }
    } catch (error) {
      console.error('Error fetching usuarios:', error);
      setNotification({
        open: true,
        message: 'Error al obtener los usuarios.',
        severity: 'error',
      });
    }
  };

  // Handlers para cambiar de pestaña
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Handlers para tipos_conteo
  const handleAddTipoConteo = async () => {
    if (!nuevoTipo.trim()) {
      setNotification({
        open: true,
        message: 'El nombre del tipo de conteo no puede estar vacío.',
        severity: 'warning',
      });
      return;
    }

    try {
      const response = await api.post('/tipos_conteo', { nombreTipo: nuevoTipo });
      if (response.data.success) {
        setTiposConteo([...tiposConteo, response.data.tipoConteo]);
        setNuevoTipo('');
        setNotification({
          open: true,
          message: 'Tipo de conteo creado exitosamente.',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Error adding tipo_conteo:', error);
      setNotification({
        open: true,
        message: 'Error al crear el tipo de conteo.',
        severity: 'error',
      });
    }
  };

  const handleEditTipoConteo = (tipo) => {
    setEditingTipo(tipo.id);
    setTipoTemp(tipo.nombreTipo);
  };

  const handleUpdateTipoConteo = async (tipoId) => {
    if (!tipoTemp.trim()) {
      setNotification({
        open: true,
        message: 'El nombre del tipo de conteo no puede estar vacío.',
        severity: 'warning',
      });
      return;
    }

    try {
      const response = await api.put(`/tipos_conteo/${tipoId}`, { nombreTipo: tipoTemp });
      if (response.data.success) {
        setTiposConteo(tiposConteo.map((tipo) => (tipo.id === tipoId ? response.data.tipoConteo : tipo)));
        setEditingTipo(null);
        setTipoTemp('');
        setNotification({
          open: true,
          message: 'Tipo de conteo actualizado exitosamente.',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Error updating tipo_conteo:', error);
      setNotification({
        open: true,
        message: 'Error al actualizar el tipo de conteo.',
        severity: 'error',
      });
    }
  };

  const handleDeleteTipoConteo = async (tipoId) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar este tipo de conteo?')) {
      return;
    }

    try {
      const response = await api.delete(`/tipos_conteo/${tipoId}`);
      if (response.data.success) {
        setTiposConteo(tiposConteo.filter((tipo) => tipo.id !== tipoId));
        setNotification({
          open: true,
          message: 'Tipo de conteo eliminado exitosamente.',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Error deleting tipo_conteo:', error);
      setNotification({
        open: true,
        message: 'Error al eliminar el tipo de conteo.',
        severity: 'error',
      });
    }
  };

  // Handlers para conteos
  const handleAddConteo = async () => {
    const { fecha, nombreConteo, idSupervisor } = nuevoConteo;
    if (!fecha || !nombreConteo || !idSupervisor) {
      setNotification({
        open: true,
        message: 'Todos los campos son requeridos para crear un conteo.',
        severity: 'warning',
      });
      return;
    }

    try {
      const response = await api.post('/conteos', nuevoConteo);
      if (response.data.success) {
        setConteos([...conteos, response.data.conteo]);
        setNuevoConteo({
          fecha: new Date().toISOString().split('T')[0], // Resetear a la fecha actual
          nombreConteo: '',
          idSupervisor: '',
        });
        setNotification({
          open: true,
          message: 'Conteo creado exitosamente.',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Error adding conteo:', error);
      setNotification({
        open: true,
        message: 'Error al crear el conteo.',
        severity: 'error',
      });
    }
  };

  const handleEditConteo = (conteo) => {
    setEditingConteo(conteo.id);
    setConteoTemp({
      fecha: conteo.fecha,
      nombreConteo: conteo.nombreConteo,
      idSupervisor: conteo.idSupervisor,
      finalizado: conteo.finalizado,
    });
  };

  const handleUpdateConteo = async (conteoId) => {
    const { fecha, nombreConteo, idSupervisor, finalizado } = conteoTemp;
    if (!fecha || !nombreConteo || !idSupervisor) {
      setNotification({
        open: true,
        message: 'Todos los campos son requeridos para actualizar el conteo.',
        severity: 'warning',
      });
      return;
    }

    try {
      const response = await api.put(`/conteos/${conteoId}`, { fecha, nombreConteo, idSupervisor, finalizado });
      if (response.data.success) {
        setConteos(conteos.map((conteo) => (conteo.id === conteoId ? response.data.conteo : conteo)));
        setEditingConteo(null);
        setConteoTemp({
          fecha: '',
          nombreConteo: '',
          idSupervisor: '',
          finalizado: false,
        });
        setNotification({
          open: true,
          message: 'Conteo actualizado exitosamente.',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Error updating conteo:', error);
      setNotification({
        open: true,
        message: 'Error al actualizar el conteo.',
        severity: 'error',
      });
    }
  };

  const handleDeleteConteo = async (conteoId) => {
    if (!window.confirm('¿Estás seguro de que deseas eliminar este conteo?')) {
      return;
    }

    try {
      const response = await api.delete(`/conteos/${conteoId}`);
      if (response.data.success) {
        setConteos(conteos.filter((conteo) => conteo.id !== conteoId));
        setNotification({
          open: true,
          message: 'Conteo eliminado exitosamente.',
          severity: 'success',
        });
      }
    } catch (error) {
      console.error('Error deleting conteo:', error);
      setNotification({
        open: true,
        message: 'Error al eliminar el conteo.',
        severity: 'error',
      });
    }
  };

  // Notificar cuando se finaliza un conteo
  const handleFinalizarConteo = async (conteoId) => {
    if (!window.confirm('¿Estás seguro de que deseas marcar este conteo como finalizado?')) {
      return;
    }

    try {
      const response = await api.put(`/conteos/${conteoId}/finalizar`);
      if (response.data.success) {
        setConteos(
          conteos.map((conteo) =>
            conteo.id === conteoId ? { ...conteo, finalizado: true } : conteo
          )
        );
        setNotification({
          open: true,
          message: 'Conteo marcado como finalizado.',
          severity: 'success',
        });
      } else {
        setNotification({
          open: true,
          message: `Error: ${response.data.message}`,
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Error finalizando conteo:', error);
      setNotification({
        open: true,
        message: 'Error al finalizar el conteo.',
        severity: 'error',
      });
    }
  };

  // Notification handler
  const handleCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="gestionar-conteos-modal">
      <Box sx={style}>
        <Typography id="gestionar-conteos-modal" variant="h6" component="h2" gutterBottom>
          Gestión de Conteos y Tipos de Conteo
        </Typography>
        <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth" sx={{ mb: 2 }}>
          <Tab label="Tipos de Conteo" />
          <Tab label="Conteos" />
        </Tabs>

        {tabIndex === 0 && (
          <Box>
            {/* Agregar Tipo de Conteo */}
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <TextField
                label="Nuevo Tipo de Conteo"
                value={nuevoTipo}
                onChange={(e) => setNuevoTipo(e.target.value)}
                fullWidth
              />
              <Button variant="contained" color="primary" onClick={handleAddTipoConteo}>
                Agregar
              </Button>
            </Box>

            {/* Lista de Tipos de Conteo */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Nombre de Tipo</TableCell>
                  <TableCell align="right">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tiposConteo.map((tipo) => (
                  <TableRow key={tipo.id}>
                    <TableCell>{tipo.id}</TableCell>
                    <TableCell>
                      {editingTipo === tipo.id ? (
                        <TextField
                          value={tipoTemp}
                          onChange={(e) => setTipoTemp(e.target.value)}
                          size="small"
                        />
                      ) : (
                        tipo.nombreTipo
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {editingTipo === tipo.id ? (
                        <>
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => handleUpdateTipoConteo(tipo.id)}
                            sx={{ mr: 1 }}
                          >
                            Guardar
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            onClick={() => setEditingTipo(null)}
                          >
                            Cancelar
                          </Button>
                        </>
                      ) : (
                        <>
                          <IconButton
                            color="primary"
                            onClick={() => handleEditTipoConteo(tipo)}
                            size="small"
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteTipoConteo(tipo.id)}
                            size="small"
                          >
                            <Delete />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {tiposConteo.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      No hay tipos de conteo disponibles.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        )}

        {tabIndex === 1 && (
          <Box>
            {/* Agregar Conteo */}
            <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>
              <TextField
                label="Fecha"
                type="date"
                value={nuevoConteo.fecha}
                onChange={(e) => setNuevoConteo({ ...nuevoConteo, fecha: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Nombre del Conteo"
                value={nuevoConteo.nombreConteo}
                onChange={(e) => setNuevoConteo({ ...nuevoConteo, nombreConteo: e.target.value })}
                fullWidth
              />
              <TextField
                select
                label="Supervisor"
                value={nuevoConteo.idSupervisor}
                onChange={(e) => setNuevoConteo({ ...nuevoConteo, idSupervisor: e.target.value })}
                SelectProps={{
                  native: true,
                }}
                fullWidth
              >
                <option value="">Selecciona un supervisor</option>
                {usuarios.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.nombreUsuario}
                  </option>
                ))}
              </TextField>
              <Button variant="contained" color="primary" onClick={handleAddConteo}>
                Agregar
              </Button>
            </Box>

            {/* Lista de Conteos */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Nombre del Conteo</TableCell>
                  <TableCell>Supervisor</TableCell>
                  <TableCell>Finalizado</TableCell>
                  <TableCell align="right">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {conteos.map((conteo) => (
                  <TableRow key={conteo.id}>
                    <TableCell>{conteo.id}</TableCell>
                    <TableCell>
                      {editingConteo === conteo.id ? (
                        <TextField
                          type="date"
                          value={conteoTemp.fecha}
                          onChange={(e) => setConteoTemp({ ...conteoTemp, fecha: e.target.value })}
                          size="small"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      ) : (
                        conteo.fecha
                      )}
                    </TableCell>
                    <TableCell>
                      {editingConteo === conteo.id ? (
                        <TextField
                          value={conteoTemp.nombreConteo}
                          onChange={(e) => setConteoTemp({ ...conteoTemp, nombreConteo: e.target.value })}
                          size="small"
                        />
                      ) : (
                        conteo.nombreConteo
                      )}
                    </TableCell>
                    <TableCell>
                      {editingConteo === conteo.id ? (
                        <TextField
                          select
                          value={conteoTemp.idSupervisor}
                          onChange={(e) =>
                            setConteoTemp({ ...conteoTemp, idSupervisor: e.target.value })
                          }
                          SelectProps={{
                            native: true,
                          }}
                          size="small"
                        >
                          <option value="">Selecciona un supervisor</option>
                          {usuarios.map((user) => (
                            <option key={user.id} value={user.id}>
                              {user.nombreUsuario}
                            </option>
                          ))}
                        </TextField>
                      ) : (
                        usuarios.find((u) => u.id === conteo.idSupervisor)?.nombreUsuario || 'N/A'
                      )}
                    </TableCell>
                    <TableCell>
                      {editingConteo === conteo.id ? (
                        <Button
                          variant="outlined"
                          color={conteoTemp.finalizado ? 'success' : 'warning'}
                          onClick={() =>
                            setConteoTemp({ ...conteoTemp, finalizado: !conteoTemp.finalizado })
                          }
                          size="small"
                        >
                          {conteoTemp.finalizado ? 'Sí' : 'No'}
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          color={conteo.finalizado ? 'success' : 'warning'}
                          onClick={() => handleFinalizarConteo(conteo.id)}
                          size="small"
                        >
                          {conteo.finalizado ? 'Sí' : 'No'}
                        </Button>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {editingConteo === conteo.id ? (
                        <>
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => handleUpdateConteo(conteo.id)}
                            sx={{ mr: 1 }}
                          >
                            Guardar
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            onClick={() => setEditingConteo(null)}
                          >
                            Cancelar
                          </Button>
                        </>
                      ) : (
                        <>
                          <IconButton
                            color="primary"
                            onClick={() => handleEditConteo(conteo)}
                            size="small"
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteConteo(conteo.id)}
                            size="small"
                          >
                            <Delete />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {conteos.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No hay conteos disponibles.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        )}

        {/* Notificaciones */}
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseNotification}
            severity={notification.severity}
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default GestionarConteosModal;
