// ArticleSearchModal.jsx
import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  IconButton,
  Tooltip,
  CircularProgress,
  Typography,
  Box,
  Snackbar,
  Alert,
  Paper,
} from '@mui/material';
import { Edit, Delete, Add, Close } from '@mui/icons-material';
import api from './api'; // Asegúrate de que la ruta sea correcta
import JsBarcode from 'jsbarcode';

const ArticleSearchModal = ({ open, handleClose, setNotification }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Estados para modales de agregar y editar
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [articuloForm, setArticuloForm] = useState({
    numeroArticulo: '',
    descripcion: '',
    codigoBarras: '',
  });

  const barcodeRef = useRef(null);
  const typingTimeoutRef = useRef(null);

  // Generar código de barras para el artículo seleccionado
  useEffect(() => {
    if (selectedArticulo && barcodeRef.current) {
      try {
        JsBarcode(barcodeRef.current, selectedArticulo.codigoBarras, {
          format: 'EAN13',
          lineColor: '#000',
          width: 2,
          height: 50,
          displayValue: true,
        });
      } catch (error) {
        console.error('Error al generar el código de barras:', error);
      }
    }
  }, [selectedArticulo]);

  // Función para buscar artículos con debounce
  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    if (searchQuery.trim().length >= 2) {
      typingTimeoutRef.current = setTimeout(() => {
        buscarArticulos();
      }, 500); // Espera 500ms después de que el usuario deja de escribir
    } else {
      setArticulos([]);
    }

    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  // Función para buscar artículos
  const buscarArticulos = async () => {
    setIsLoading(true);
    try {
      const response = await api.get('/buscar_articulos', {
        params: { query: searchQuery },
      });

      if (response.data.success) {
        setArticulos(response.data.articulos);
      } else {
        setArticulos([]);
        setNotification({
          open: true,
          message: 'No se encontraron artículos.',
          severity: 'info',
        });
      }
    } catch (error) {
      console.error('Error al buscar artículos:', error);
      setNotification({
        open: true,
        message: 'Error al buscar artículos.',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Manejar selección de artículo
  const handleSelectArticulo = (articulo) => {
    setSelectedArticulo(articulo);
  };

  // Funciones para agregar y editar artículos
  const handleOpenAddModal = () => {
    setIsEditMode(false);
    setArticuloForm({
      numeroArticulo: '',
      descripcion: '',
      codigoBarras: '',
    });
    setIsAddEditModalOpen(true);
  };

  const handleOpenEditModal = () => {
    if (!selectedArticulo) return;
    setIsEditMode(true);
    setArticuloForm({
      numeroArticulo: selectedArticulo.numeroArticulo,
      descripcion: selectedArticulo.descripcion,
      codigoBarras: selectedArticulo.codigoBarras,
    });
    setIsAddEditModalOpen(true);
  };

  const handleCloseAddEditModal = () => {
    setIsAddEditModalOpen(false);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setArticuloForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmitArticulo = async () => {
    // Validación básica
    if (!articuloForm.descripcion || !articuloForm.codigoBarras || !articuloForm.numeroArticulo) {
      setNotification({
        open: true,
        message: 'Descripción, Código de Producto y Código de Barras son obligatorios.',
        severity: 'warning',
      });
      return;
    }

    try {
      if (isEditMode) {
        // Editar artículo
        const response = await api.put(`/articulos/${selectedArticulo.id}`, articuloForm);
        if (response.data.success) {
          setNotification({
            open: true,
            message: 'Artículo actualizado exitosamente.',
            severity: 'success',
          });
          buscarArticulos();
          handleCloseAddEditModal();
        } else {
          setNotification({
            open: true,
            message: 'Error al actualizar el artículo.',
            severity: 'error',
          });
        }
      } else {
        // Agregar artículo
        const response = await api.post('/articulos', articuloForm);
        if (response.data.success) {
          setNotification({
            open: true,
            message: 'Artículo agregado exitosamente.',
            severity: 'success',
          });
          buscarArticulos();
          handleCloseAddEditModal();
        } else {
          setNotification({
            open: true,
            message: 'Error al agregar el artículo.',
            severity: 'error',
          });
        }
      }
    } catch (error) {
      console.error('Error al guardar el artículo:', error);
      setNotification({
        open: true,
        message: 'Error al guardar el artículo.',
        severity: 'error',
      });
    }
  };

  // Funciones para eliminar artículo
  const handleDeleteArticulo = async () => {
    if (!selectedArticulo) return;

    if (!window.confirm('¿Estás seguro de que deseas eliminar este artículo?')) return;

    try {
      const response = await api.delete(`/articulos/${selectedArticulo.id}`);
      if (response.data.success) {
        setNotification({
          open: true,
          message: 'Artículo eliminado exitosamente.',
          severity: 'success',
        });
        setSelectedArticulo(null);
        buscarArticulos();
      } else {
        setNotification({
          open: true,
          message: 'Error al eliminar el artículo.',
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Error al eliminar el artículo:', error);
      setNotification({
        open: true,
        message: 'Error al eliminar el artículo.',
        severity: 'error',
      });
    }
  };

  return (
    <>
      {/* Modal Principal */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>
          Buscar Artículos
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* Barra de Búsqueda */}
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={8}>
              <TextField
                label="Buscar por Descripción, Código de Producto o Código de Barras"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    buscarArticulos();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
              <Tooltip title="Agregar Nuevo Artículo">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={handleOpenAddModal}
                >
                  Agregar
                </Button>
              </Tooltip>
              <Tooltip title="Editar Artículo Seleccionado">
                <span>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Edit />}
                    onClick={handleOpenEditModal}
                    disabled={!selectedArticulo}
                    sx={{ ml: 1 }}
                  >
                    Editar
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title="Eliminar Artículo Seleccionado">
                <span>
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<Delete />}
                    onClick={handleDeleteArticulo}
                    disabled={!selectedArticulo}
                    sx={{ ml: 1 }}
                  >
                    Eliminar
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>

          {/* Tabla de Artículos */}
          <Box sx={{ mt: 3 }}>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            ) : articulos.length > 0 ? (
              <Paper sx={{ maxHeight: 400, overflow: 'auto' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Número de Producto</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Descripción</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Código de Barras</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Fabricante</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Unidad de Medida</th>
                    </tr>
                  </thead>
                  <tbody>
                    {articulos.map((articulo) => (
                      <tr
                        key={articulo.id}
                        onClick={() => handleSelectArticulo(articulo)}
                        style={{
                          backgroundColor:
                            selectedArticulo && selectedArticulo.id === articulo.id ? '#f0f8ff' : 'white',
                          cursor: 'pointer',
                        }}
                      >
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{articulo.numeroArticulo}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{articulo.descripcion}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{articulo.codigoBarras}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{articulo.fabricante}</td>
                        <td style={{ border: '1px solid #ddd', padding: '8px' }}>{articulo.unidadMedida}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Paper>
            ) : (
              <Typography sx={{ mt: 2 }}>No se encontraron artículos.</Typography>
            )}
          </Box>

          {/* Código de Barras Seleccionado */}
          {selectedArticulo && (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Typography variant="h6" gutterBottom>
                Código de Barras Seleccionado
              </Typography>
              <svg ref={barcodeRef}></svg>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal para Agregar o Editar Artículo */}
      <Dialog open={isAddEditModalOpen} onClose={handleCloseAddEditModal} fullWidth maxWidth="sm">
        <DialogTitle>
          {isEditMode ? 'Editar Artículo' : 'Agregar Nuevo Artículo'}
          <IconButton
            aria-label="close"
            onClick={handleCloseAddEditModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Descripción"
                name="descripcion"
                variant="outlined"
                fullWidth
                value={articuloForm.descripcion}
                onChange={handleFormChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Código de Producto"
                name="numeroArticulo"
                variant="outlined"
                fullWidth
                value={articuloForm.numeroArticulo}
                onChange={handleFormChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Código de Barras"
                name="codigoBarras"
                variant="outlined"
                fullWidth
                value={articuloForm.codigoBarras}
                onChange={handleFormChange}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddEditModal} color="secondary" variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleSubmitArticulo} color="primary" variant="contained">
            {isEditMode ? 'Guardar Cambios' : 'Agregar Artículo'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArticleSearchModal;
