// ArticleSelectionModal.jsx
import React, { useEffect, useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import api from './apiV';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const ArticleSelectionModal = ({ open, handleClose, onSelectionComplete }) => {
  const [articulos, setArticulos] = useState([]);
  const [filteredArticulos, setFilteredArticulos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedArticulos, setSelectedArticulos] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const gridRef = useRef(null);

  useEffect(() => {
    if (open) {
      fetchArticulos();
    } else {
      // Limpiar el estado cuando el modal se cierra
      setSelectedArticulos([]);
      setSearchTerm('');
    }
  }, [open]);

  const fetchArticulos = async () => {
    setLoading(true);
    try {
      const response = await api.get('/articulos');
      setArticulos(response.data);
    } catch (error) {
      console.error('Error al obtener los artículos:', error);
      setNotification({
        open: true,
        message: 'Error al cargar los artículos.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  // Efecto para actualizar los artículos filtrados cuando cambien 'articulos' o 'searchTerm'
  useEffect(() => {
    if (searchTerm === '') {
      setFilteredArticulos(articulos);
    } else {
      const lowerSearchTerm = searchTerm.toLowerCase();
      const filtered = articulos.filter((articulo) =>
        articulo.articulo.toLowerCase().includes(lowerSearchTerm) ||
        articulo.cod_articulo.toLowerCase().includes(lowerSearchTerm)
      );
      setFilteredArticulos(filtered);
    }
  }, [articulos, searchTerm]);

  const handleSelectionChanged = () => {
    if (gridRef.current) {
      const selectedNodes = gridRef.current.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      setSelectedArticulos(selectedData);
      console.log('Artículos seleccionados:', selectedData);
    }
  };

  const handleConfirmSelection = () => {
    if (selectedArticulos.length === 0) {
      setNotification({
        open: true,
        message: 'Debes seleccionar al menos un artículo.',
        severity: 'warning',
      });
      return;
    }
    onSelectionComplete(selectedArticulos);
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const handleCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const onGridReady = (params) => {
    console.log('Grid API en onGridReady:', params.api);
    params.api.sizeColumnsToFit();
  };

  const getRowId = (params) => {
    return params.data.cod_articulo; // Asegúrate de que 'cod_articulo' es único
  };

  const columnDefs = [
    {
      headerName: '',
      field: 'checkbox',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      width: 50,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Código de Artículo',
      field: 'cod_articulo',
      sortable: true,
      filter: 'agTextColumnFilter',
      flex: 1,
    },
    {
      headerName: 'Artículo',
      field: 'articulo',
      sortable: true,
      filter: 'agTextColumnFilter',
      flex: 2,
    },
    // Agrega más columnas según tus necesidades
  ];

  return (
    <>
      <Dialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
        <DialogTitle>Seleccionar Artículos</DialogTitle>
        <DialogContent>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TextField
                label="Buscar Artículos"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={handleSearch}
                sx={{ mb: 2 }}
              />
              <div
                className="ag-theme-alpine"
                style={{ height: '400px', width: '100%' }}
              >
                <AgGridReact
                  ref={gridRef}
                  onGridReady={onGridReady}
                  rowData={filteredArticulos} // Usamos los artículos filtrados
                  columnDefs={columnDefs}
                  rowSelection="multiple"
                  onSelectionChanged={handleSelectionChanged}
                  getRowId={getRowId}
                  defaultColDef={{
                    flex: 1,
                    minWidth: 150,
                    sortable: true,
                    filter: true,
                  }}
                  pagination={true}
                  paginationPageSize={20}
                  overlayNoRowsTemplate="<span>No se encontraron artículos.</span>"
                />
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleConfirmSelection} variant="contained" color="primary">
            Confirmar Selección
          </Button>
        </DialogActions>
      </Dialog>

      {/* Notificaciones */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ArticleSelectionModal;
