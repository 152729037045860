// EditModal.jsx
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@mui/material';

const EditModal = ({ open, handleClose, data, onSubmit }) => {
  const [formData, setFormData] = useState({
    id: '',
    cantidadContada: '',
  });

  useEffect(() => {
    if (data) {
      setFormData({
        id: data.id,
        cantidadContada: data.cantidadContada || '',
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Editar Registro</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              label="Cantidad Contada"
              name="cantidadContada"
              value={formData.cantidadContada}
              onChange={handleChange}
              type="number"
              fullWidth
              required
              inputProps={{ min: 0 }}
            />
          </Grid>
          {/* Agrega más campos si es necesario */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
