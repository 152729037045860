// LoginPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from './api';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Avatar,
  CssBaseline,
  Alert,
  Snackbar,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const LoginPage = () => {
  const [username, setUsername] = useState(''); // Estado para el nombre de usuario
  const [password, setPassword] = useState(''); // Estado para la contraseña
  const [error, setError] = useState(''); // Estado para manejar errores
  const [loading, setLoading] = useState(false); // Estado para manejar el indicador de carga
  const navigate = useNavigate();

  useEffect(() => {
    // Si el usuario ya ha iniciado sesión, redirigir al dashboard
    const userData = localStorage.getItem('userData');
    if (userData) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // Realizar la solicitud al servidor
      const response = await api.post('/login', { username, password });

      if (response.status === 200 && response.data.success) {
        // Login exitoso
        const userData = {
          userId: response.data.userId,
          username: username,
        };
        // Guardar los datos del usuario en localStorage de forma segura
        localStorage.setItem('userData', JSON.stringify(userData));
        navigate('/dashboard', { replace: true }); // Redirigir al dashboard
      } else {
        // Manejar errores de autenticación
        setError(response.data.message || 'Error en el inicio de sesión');
      }
    } catch (err) {
      // Manejar errores de servidor o de red
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('Error en el servidor. Inténtelo de nuevo más tarde.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Card sx={{ mt: 8, p: 4, borderRadius: 3, boxShadow: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
            Iniciar Sesión
          </Typography>
          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Usuario"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant="outlined"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
            />
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{ mt: 3, mb: 2, fontWeight: 'bold' }}
            >
              {loading ? 'Ingresando...' : 'Iniciar Sesión'}
            </Button>
          </Box>
        </Box>
      </Card>
    </Container>
  );
};

export default LoginPage;
