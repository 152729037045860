// ConteoDetalleTable.jsx
import React, { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import api from './api'; // Asegúrate de que este archivo esté correctamente configurado
import JsBarcode from 'jsbarcode';
import {
  Container,
  Typography,
  Paper,
  Box,
  Divider,
  Tabs,
  Tab,
  Button,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Menu,
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsIcon from '@mui/icons-material/Settings';
import ArticleSearchModal from './ArticleSearchModal';
import AsignacionesModal from './AsignacionesModal';
import EditModal from './EditModal';
import DeleteConfirmDialog from './DeleteConfirmDialog';
import GestionarConteosModal from './GestionarConteosModal';

const ConteoDetalleTable = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  // Validación de inicio de sesión
  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData && storedUserData !== 'undefined') {
      try {
        const parsedUserData = JSON.parse(storedUserData);
        if (parsedUserData && parsedUserData.username) {
          setUserData(parsedUserData);
        } else {
          throw new Error('Datos de usuario inválidos');
        }
      } catch (error) {
        console.error('Error al analizar los datos de usuario:', error);
        localStorage.removeItem('userData');
        navigate('/', { replace: true });
      }
    } else {
      navigate('/', { replace: true });
    }
  }, [navigate]);

  // Manejo del menú de usuario
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('userData');
    navigate('/', { replace: true });
  };

  // Estados principales
  const [tabIndex, setTabIndex] = useState(0);
  const [ungroupedData, setUngroupedData] = useState([]);
  const [ungroupedColumns, setUngroupedColumns] = useState([]);
  const [allArticlesData, setAllArticlesData] = useState([]);
  const [allArticlesColumns, setAllArticlesColumns] = useState([]);
  const [selectedBarcode, setSelectedBarcode] = useState('');
  const [selectedConteo, setSelectedConteo] = useState(null); // Estado para el conteo seleccionado
  const [conteos, setConteos] = useState([]); // Lista de conteos para el Select
  const barcodeRef = useRef(null);

  // Estados para filas seleccionadas y modales
  const [selectedRow, setSelectedRow] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [articleModalOpen, setArticleModalOpen] = useState(false);
  const [asignacionesModalOpen, setAsignacionesModalOpen] = useState(false);
  const [gestionModalOpen, setGestionModalOpen] = useState(false);

  // Estados para notificaciones
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const socketRef = useRef(null);

  // Conexión a Socket.IO
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io('https://dicsapps.space:10000');

      // Eventos de actualización de detalles del conteo
      socketRef.current.on('conteoDetallesSinAgruparActualizados', (updatedData) => {
        if (Array.isArray(updatedData)) {
          setUngroupedData(updatedData);
        } else {
          console.error('Formato de datos inválido para datos desagrupados:', updatedData);
        }
      });

      // Eventos de actualización de todos los artículos
      socketRef.current.on('articulosConteoActualizados', (updatedData) => {
        if (Array.isArray(updatedData)) {
          setAllArticlesData(updatedData);
        } else {
          console.error('Formato de datos inválido para todos los artículos:', updatedData);
        }
      });

      // Eventos de actualización de conteos
      socketRef.current.on('conteosActualizados', (updatedConteo) => {
        console.log('Evento conteosActualizados recibido:', updatedConteo);
        setConteos((prevConteos) => {
          const index = prevConteos.findIndex((c) => c.id === updatedConteo.id);
          if (index !== -1) {
            const newData = [...prevConteos];
            newData[index] = updatedConteo;
            return newData;
          } else {
            return [...prevConteos, updatedConteo];
          }
        });
      });

      socketRef.current.on('conteosEliminados', (conteoId) => {
        setConteos((prevConteos) => {
          return prevConteos.filter((c) => c.id !== conteoId);
        });
      });
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  // Fetch inicial de datos
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Datos desagrupados
        const ungroupedResponse = await api.get('/conteo_detalle_sin_agrupacion');
        const initialUngroupedData = ungroupedResponse.data.conteoDetallesSinAgrupar;
        setUngroupedData(initialUngroupedData);

        if (initialUngroupedData.length > 0) {
          const dynamicUngroupedColumns = Object.keys(initialUngroupedData[0]).map((key) => {
            let headerName = key.charAt(0).toUpperCase() + key.slice(1);
            // Renombrar campos específicos
            if (key === 'numeroArticulo') headerName = 'Número de Producto';
            if (key === 'descripcion') headerName = 'Descripción';
            if (key === 'codigoBarras') headerName = 'Código de Barras';
            if (key === 'nombreUsuario') headerName = 'Nombre de Usuario';
            if (key === 'nombreTipo') headerName = 'Tipo de Conteo';
            if (key === 'nombreConteo') headerName = 'Nombre de Conteo';
            if (key === 'cantidadContada') headerName = 'Cantidad Contada';
            if (key === 'finalizado') headerName = 'Finalizado';
            if (key === 'fecha') headerName = 'Fecha';
            if (key === 'nombreSupervisor') headerName = 'Supervisor';

            return {
              headerName: headerName,
              field: key,
              sortable: true,
              filter: true,
              resizable: true,
              cellStyle: { fontSize: '0.875rem', padding: '4px' },
              valueFormatter:
                key === 'finalizado'
                  ? (params) => (params.value ? 'Sí' : 'No')
                  : undefined,
            };
          });
          setUngroupedColumns(dynamicUngroupedColumns);
        }

        // Todos los artículos
        const allArticlesResponse = await api.get('/articulos_conteo');
        const initialAllArticlesData = allArticlesResponse.data.articulosConteo;
        setAllArticlesData(initialAllArticlesData);

        if (initialAllArticlesData.length > 0) {
          const dynamicAllArticlesColumns = Object.keys(initialAllArticlesData[0]).map((key) => {
            let headerName = key.charAt(0).toUpperCase() + key.slice(1);
            // Renombrar campos específicos
            if (key === 'numeroArticulo') headerName = 'Número de Producto';
            if (key === 'descripcion') headerName = 'Descripción';
            if (key === 'codigoBarras') headerName = 'Código de Barras';

            return {
              headerName: headerName,
              field: key,
              sortable: true,
              filter: true,
              resizable: true,
              cellStyle: (params) => {
                if (key === 'TotalContado' && params.value === 0) {
                  return { backgroundColor: '#fdd', color: '#900', fontSize: '0.875rem', padding: '4px' };
                }
                return { fontSize: '0.875rem', padding: '4px' };
              },
            };
          });
          setAllArticlesColumns(dynamicAllArticlesColumns);
        }

        // Obtener la lista de conteos desde el endpoint /api/conteos_lista
        const conteosResponse = await api.get('/conteos_lista');
        const initialConteos = conteosResponse.data.conteos;
        setConteos(initialConteos);
      } catch (error) {
        console.error('Error al obtener los detalles del conteo:', error);
        setNotification({
          open: true,
          message: 'Error al cargar los datos iniciales.',
          severity: 'error',
        });
      }
    };

    fetchData();
  }, []);

  // Generar código de barras para selección
  useEffect(() => {
    if (selectedBarcode && barcodeRef.current) {
      try {
        JsBarcode(barcodeRef.current, selectedBarcode, {
          format: 'EAN13',
          lineColor: '#000',
          width: 2,
          height: 50,
          displayValue: true,
        });
      } catch (error) {
        console.error('Error al generar el código de barras:', error);
      }
    }
  }, [selectedBarcode]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setSelectedBarcode('');
    setSelectedRow(null);
  };

  // Handlers para edición
  const handleEdit = () => {
    if (selectedRow) {
      setEditModalOpen(true);
    }
  };

  const handleEditSubmit = async (updatedData) => {
    try {
      const response = await api.put(`/conteo_detalle/${updatedData.id}`, {
        cantidadContada: updatedData.cantidadContada,
      });
      if (response.data.success) {
        setEditModalOpen(false);
        setNotification({
          open: true,
          message: 'Registro actualizado correctamente.',
          severity: 'success',
        });
      } else {
        setNotification({
          open: true,
          message: `Error al actualizar: ${response.data.message}`,
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Error al actualizar el registro:', error);
      setNotification({
        open: true,
        message: 'Error al conectar con el servidor.',
        severity: 'error',
      });
    }
  };

  // Handlers para eliminación
  const handleDelete = () => {
    if (selectedRow) {
      setDeleteConfirmOpen(true);
    }
  };

  const confirmDelete = async () => {
    try {
      const response = await api.delete(`/conteo_detalle/${selectedRow.id}`);
      if (response.data.success) {
        setDeleteConfirmOpen(false);
        setNotification({
          open: true,
          message: 'Registro eliminado correctamente.',
          severity: 'success',
        });
        setSelectedRow(null);
      } else {
        setNotification({
          open: true,
          message: `Error al eliminar: ${response.data.message}`,
          severity: 'error',
        });
      }
    } catch (error) {
      console.error('Error al eliminar el registro:', error);
      setNotification({
        open: true,
        message: 'Error al eliminar el registro.',
        severity: 'error',
      });
    }
  };

  // Handlers para gestión de asignaciones
  const handleOpenAsignacionesModal = () => {
    setAsignacionesModalOpen(true);
  };

  const handleCloseAsignacionesModal = () => {
    setAsignacionesModalOpen(false);
  };

  // Handlers para búsqueda de artículos
  const handleOpenArticleModal = () => {
    setArticleModalOpen(true);
  };

  const handleCloseArticleModal = () => {
    setArticleModalOpen(false);
  };

  // Handlers para gestionar conteos y tipos de conteo
  const handleOpenGestionModal = () => {
    setGestionModalOpen(true);
  };

  const handleCloseGestionModal = () => {
    setGestionModalOpen(false);
  };

  // Cerrar notificaciones
  const handleCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  // Calcular la cantidad de productos distintos contados
  const distinctProductCount = () => {
    let data = [];
    if (tabIndex === 0) {
      data = selectedConteo
        ? ungroupedData.filter((c) => c.nombreConteo === selectedConteo.nombreConteo)
        : ungroupedData;
    } else if (tabIndex === 1) {
      data = allArticlesData;
    }

    const distinctCount = new Set(data.map((item) => item.numeroArticulo)).size;
    return distinctCount;
  };

  return (
    <Box sx={{ bgcolor: '#f0f2f5', minHeight: '100vh' }}>
      {/* Barra de navegación */}
      <AppBar position="static" color="primary" elevation={0}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
            Detalles de Conteo
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Abrir configuración">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={userData ? userData.username : 'Usuario'}>
                  <AccountCircleIcon />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem disabled>
                <Typography textAlign="center">{userData ? userData.username : 'Usuario'}</Typography>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
                <Typography textAlign="center">Cerrar Sesión</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Contenido principal */}
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        {/* Encabezado */}
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Detalles de Conteo
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Datos Desagrupados" />
            <Tab label="Todos los Artículos" />
          </Tabs>
        </Paper>

        {/* Botones de Acción y Select de Conteo */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          {/* Combo Box para Seleccionar Conteo */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
            <Typography variant="subtitle1" sx={{ alignSelf: 'center' }}>
              Seleccionar Conteo:
            </Typography>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="select-conteo-label">Conteo</InputLabel>
              <Select
                labelId="select-conteo-label"
                value={selectedConteo ? selectedConteo.nombreConteo : ''}
                label="Conteo"
                onChange={(e) => {
                  const selected = conteos.find((c) => c.nombreConteo === e.target.value);
                  setSelectedConteo(selected || null);
                  setSelectedRow(null);
                  setSelectedBarcode(selected ? selected.codigoBarras : '');
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Selecciona un conteo</em>;
                  }
                  return selected;
                }}
              >
                <MenuItem disabled value="">
                  <em>Selecciona un conteo</em>
                </MenuItem>
                {conteos.map((conteo) => (
                  <MenuItem
                    key={conteo.id}
                    value={conteo.nombreConteo}
                    sx={{
                      color: conteo.finalizado ? 'red' : 'green',
                      fontWeight: conteo.finalizado ? 'bold' : 'normal',
                    }}
                  >
                    {conteo.nombreConteo} {conteo.finalizado ? '(Finalizado)' : '(Activo)'}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* Botones de Acción */}
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {tabIndex === 0 && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenArticleModal}
                  size="small"
                  startIcon={<SearchIcon />}
                >
                  Buscar Artículo
                </Button>
                <Tooltip title={!selectedRow ? 'Selecciona un registro para editar' : ''}>
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEdit}
                      disabled={!selectedRow}
                      size="small"
                      startIcon={<EditIcon />}
                    >
                      Editar
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip title={!selectedRow ? 'Selecciona un registro para eliminar' : ''}>
                  <span>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleDelete}
                      disabled={!selectedRow}
                      size="small"
                      startIcon={<DeleteIcon />}
                    >
                      Eliminar
                    </Button>
                  </span>
                </Tooltip>
              </>
            )}
            {/* Botón para gestionar asignaciones */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenAsignacionesModal}
              size="small"
              startIcon={<AssignmentIcon />}
            >
              Gestionar Asignaciones
            </Button>

            {/* Botón para gestionar conteos */}
            <Button
              variant="contained"
              color="info"
              onClick={handleOpenGestionModal}
              size="small"
              startIcon={<SettingsIcon />}
            >
              Gestionar Conteos
            </Button>
          </Box>
        </Box>

        {/* Sección para mostrar la cantidad de productos distintos contados */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1">
            <strong>Cantidad de Productos Distintos Contados:</strong> {distinctProductCount()}
          </Typography>
        </Box>

        {/* Tabla de Datos */}
        <Paper elevation={3} sx={{ p: 1, mb: 4, height: '60vh', overflow: 'hidden' }}>
          <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
            <AgGridReact
              rowData={
                tabIndex === 0
                  ? selectedConteo
                    ? ungroupedData.filter((c) => c.nombreConteo === selectedConteo.nombreConteo)
                    : ungroupedData
                  : tabIndex === 1
                  ? allArticlesData
                  : []
              }
              columnDefs={
                tabIndex === 0
                  ? ungroupedColumns
                  : tabIndex === 1
                  ? allArticlesColumns
                  : []
              }
              getRowId={(params) => params.data.id || params.data.codigoBarras}
              defaultColDef={{
                flex: 1,
                minWidth: 120,
                sortable: true,
                filter: true,
                resizable: true,
                cellStyle: { fontSize: '0.875rem', padding: '4px' },
                headerClass: 'custom-header',
              }}
              headerHeight={35}
              rowHeight={30}
              pagination={true}
              paginationPageSize={20}
              rowSelection={tabIndex === 1 ? 'none' : 'single'}
              onSelectionChanged={(event) => {
                const selectedRows = event.api.getSelectedRows();
                if (tabIndex === 0 && selectedRows.length > 0 && selectedRows[0].codigoBarras) {
                  setSelectedRow(selectedRows[0]);
                  setSelectedBarcode(selectedRows[0].codigoBarras);
                } else {
                  setSelectedRow(null);
                  setSelectedBarcode('');
                }
              }}
            />
          </div>
        </Paper>

        {/* Código de Barras y Número de Producto Seleccionado */}
        {selectedRow && tabIndex === 0 && (
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Detalles del Artículo Seleccionado
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              <strong>Número de Producto:</strong> {selectedRow.numeroArticulo}
            </Typography>
            <svg ref={barcodeRef}></svg>
          </Box>
        )}

        {/* Modales */}
        <EditModal
          open={editModalOpen}
          handleClose={() => setEditModalOpen(false)}
          data={selectedRow}
          onSubmit={handleEditSubmit}
        />

        <DeleteConfirmDialog
          open={deleteConfirmOpen}
          handleClose={() => setDeleteConfirmOpen(false)}
          onConfirm={confirmDelete}
        />

        <ArticleSearchModal
          open={articleModalOpen}
          handleClose={handleCloseArticleModal}
          setNotification={setNotification}
        />

        <AsignacionesModal
          open={asignacionesModalOpen}
          handleClose={handleCloseAsignacionesModal}
        />

        {/* Nuevo Modal para gestionar conteos y tipos de conteo */}
        <GestionarConteosModal
          open={gestionModalOpen}
          handleClose={handleCloseGestionModal}
        />

        {/* Notificaciones */}
        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleCloseNotification}
            severity={notification.severity}
            sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default ConteoDetalleTable;
